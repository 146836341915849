import { APIClient } from './api_helper'

import * as url from "./url_helper";

const api = new APIClient();

//*Auth 
export const postRegisterUser = (data) => api.create(url.REGISTER_USER, data);
export const postLoginUser = (data) => api.create(url.LOGIN_USER, data);
export const postForgotPasswordUser = (data) => api.create(url.FORGOT_PASSWORD, data);
export const postResetPasswordUser = (data) => api.create(url.RESET_PASSWORD, data);
export const postLoginUserWithFacebook = (data) => api.create(url.LOGIN_USER_WITH_FACEBOOK, data);


export const putProfileUser = (data) => api.create(url.PROFILE_UPDATE, data);
export const postProfileKYC = (data) => api.create(url.PROFILE_UPDATE_KYC, data);

export const postVerifyOtp = (data) => api.create(url.VERIFY_OTP, data);
export const postResendVerificationOtp = (data) => api.create(url.RESEND_OTP, data);

//*common 
export const getUserProfileDetails = () => api.get(url.PROFILE_GET);
export const postHostListProduct = (data) => api.create(url.HOST_LIST_PRODUCT, data);
export const postProductDetails = (data) => api.create(url.PROFILE_DETAILS, data);
export const postPaymentCheckout = (data) => api.create(url.PAYMENT_CHECKOUT, data);
export const getPaymentList = (data) => api.create(url.LIST_PAYMENT, data);
export const postFinalPaymentCheckout = (data) => api.create(url.FINAL_PAYMENT_CHECKOUT, data);

export const postUpdateOrderStatus = (data) => {
  return api.create(
    url.UPDATE_ORDER_STATUS(
      data.order_id
    ),
    {
      "order_status": data.order_status,
    }
  );
};

export const postUpdateOrderStatusSignRenterAgreement = (data) => {
  return api.get(
    url.UPDATE_ORDER_STATUS_SIGN_IN_RENTER_AGREEMENT(
      data.order_id
    )
  );
};

export const postSendMessage = (data) => api.create(url.CHAT_SERVICE, data);
export const getFetchMessageDetails = (orderId) => {
  if(orderId) {
    return api.get(`${url.CHAT_SERVICE}?orderId=${orderId}&page=1&limit=200`)
  }
};
export const getSiteSettingDetail = () => api.get(url.GET_SITE_SETTING_DETAILS);
export const postContactUS = (data) => api.create(url.POST_CONTACT_US, data);
export const postGetCity = (data) => api.create(url.POST_GET_CITY, data);
export const postCalculateOrderPayment = (data) => api.create(url.CALCULATE_ORDER_PAYMENT, data);

//*Renter
export const getRenterOrderList = (data) => api.create(url.RENTER_LIST_ORDER, data);
export const postRenterOrderDetails = (data) => api.create(url.RENTER_ORDER_DETAILS, data);
export const postRenterProductReview = (data) => api.create(url.RENTER_ADD_REVIEW_PRODUCT, data);

export const postUpdateProductReview = (data) => {
  return api.create(
    url.UPDATE_REVIEW_PRODUCT(
      data.product_id
    ),
    {
      "text": data.text,
      "rate": data.rate
    }
  );
};

//*host
export const getHostOrderList = (data) => api.create(url.HOST_LIST_ORDER, data);
export const postHostOrderDetails = (data) => api.create(url.HOST_ORDER_DETAILS, data);
export const postHostProductReview = (data) => api.create(url.HOST_ADD_REVIEW_PRODUCT, data);

export const postUpdateHostProductReview = (data) => {
  return api.create(
    url.HOST_UPDATE_REVIEW_PRODUCT(
      data.product_id
    ),
    {
      "text": data.text,
      "rate": data.rate
    }
  );
};

//*HostProcuctCrud
export const postListHostProduct = (data) => api.create(url.LIST_HOST_PRODUCT, data);
export const postAddListHostProduct = (data) => api.create(url.ADD_LIST_HOST_PRODUCT, data);
export const postGetListHostProduct = (data) => api.create(url.GET_EDIT_HOST_PRODUCT, data);
export const postEditImageListHostProduct = (data) => api.create(url.EDIT_LIST_HOST_PRODUCT, data);
export const postGetListHostProtectionProof = (data) => api.create(url.GET_PROTECTION_PROOF, data);
export const postDeleteHostProtectionProof = (data) => api.create(url.DELETE_PROTECTION_PROOF, data);
export const postDeleteHostProtectionProofSingle = (data) => api.create(url.DELETE_PROTECTION_PROOF_SINGLE, data);


export const postEditListHostProduct = (data, id) => {
  return api.create(
    url.EDIT_LIST_HOST_PRODUCT(
      id
    ), data
  );
};

export const postDeleteListHostProduct = (data) => {
  return api.create(
    url.DELETE_LIST_HOST_PRODUCT(
      data.id
    )
  );
};

//*promoCode
export const promoCodeDetails = (data) => api.create(url.PROMO_CODE_DETAILS, data);
export const addPromoCodeAndHostRelation = (data) => api.create(url.ADD_PROMO_CODE_HOST_RELATION, data);